import {ApiRequest} from "~/utils/api-request";

const toggleFavorite = async(lotId, add) => {
    let api_url = (add) ? `lots/${lotId}/favorites/set` : `lots/${lotId}/favorites/unset`;

    let apiRequest = new ApiRequest('post', api_url, null);

    apiRequest.onSuccess((data) => {
        // console.log(data);

    });

    apiRequest.onError((error) => {
        // errors

    });

    let data = await apiRequest.request();
    // console.log(data);
}

export {toggleFavorite}