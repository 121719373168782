import {Ref} from "vue";
import {ApiRequest} from "~/utils/api-request";
import type {ErrorMessage} from "~/@types";
import { initialError } from "~/@types";

const getUserBids = async(ids) => {

    let apiRequest = new ApiRequest('get', 'lots/bids/user-bids');

    apiRequest.onSuccess((data) => {

    });

    apiRequest.onError((error) => {
        // errors
    });

    apiRequest.setQuery({
        lotsId: ids
    })

    return apiRequest.request();
}

async function setAutoBetOn ({
     id,
     betValue,
     autoBet,
     priceLoading,
     errors,
     isAutoBetSet,
     isLotNotFound
}: {
    id: string,
    betValue: Ref<number>,
    autoBet: Ref<boolean>,
    priceLoading: Ref<boolean>,
    isAutoBetSet: Ref<boolean>,
    errors: Ref<ErrorMessage>,
    isLotNotFound: Ref<boolean>,
}) {
    const setAutoBidOn = new ApiRequest('post', 'lots/bids/' + id + '/set-user-auto-bid', {},
        { limit: betValue.value }
    );
    setAutoBidOn.onSuccess((data) => {
        isAutoBetSet.value = true;
        betValue.value = undefined;
        priceLoading.value = true;
        Object.assign(errors.value, initialError);
    });
    setAutoBidOn.onError((error) => {
        console.error('setAutoBidOn', error);
        autoBet.value = false;
        priceLoading.value = false;
        Object.assign(errors.value, error);
    });
    setAutoBidOn.onNotFoundError((error) => {
        console.error('setAutoBidOn 404', error);
        isLotNotFound.value = true;
        Object.assign(errors.value, error);
        autoBet.value = false;
        priceLoading.value = false;
        betValue.value = undefined;
    })
    await setAutoBidOn.request()
}

async function setAutoBetOff ({
      id,
      errors,
      autoBet,
      isAutoBetSet
}: {
    id: string,
    autoBet: Ref<boolean>,
    isAutoBetSet: Ref<boolean>
    errors: Ref<ErrorMessage>,
}) {
    const setAutoBidOff = new ApiRequest('post', 'lots/bids/' + id + '/unset-user-auto-bid', {},);
    setAutoBidOff.onSuccess((data) => {
        console.log('removeAutoBet', data);
        isAutoBetSet.value = false;
        autoBet.value = false;
        Object.assign(errors.value, initialError);
    });
    setAutoBidOff.onError((error) => {
        console.error('removeAutoBet', error);
        Object.assign(errors.value, error);
    });

    await setAutoBidOff.request()
}

export {getUserBids, setAutoBetOn, setAutoBetOff};
