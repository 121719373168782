<template>
  <swiper
      class="timeline"
      :slides-per-view="'auto'"
      :initial-slide="initialIndex"
  >
    <swiper-slide
        v-for="event in events"
        class="timeline-event"
        :class="{'active': event.active}"
    >
      <div
          class="timeline-event-opposite"
          v-html="event.date"
          v-if="event.active"
      ></div>
      <div class="timeline-event-separator"></div>
      <div
          class="timeline-event-content"
          v-html="event.status"
      ></div>
      <div class="timeline-event-marker"></div>
    </swiper-slide>
  </swiper>
</template>

<script setup lang="ts">
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import 'swiper/css';
  import { ref } from 'vue';

  interface TradeTimelineEvent {
    date: string;
    status: string;
    active: boolean;
  }

  const props = defineProps<{ events: TradeTimelineEvent[] }>();

  let initialIndex = ref(0);

  for (let i = 0; i < props.events.length; i++){
    if (props.events[i].active) {initialIndex.value = i}
  }
</script>

<style scoped>
.timeline{
  padding: 16px;
  margin: 0 6.6% 10px;
  /*display: flex;*/
  overflow: visible;
}
.timeline-event{
  text-align: center;
  flex: 1;
  position: relative;
}
.timeline-event:last-child{
  flex: 0;
  width: 0;
}
.timeline-event-opposite,
.timeline-event-content{
  position: absolute;
  left: 0;
  transform: translate(-50%, 0);
  color: #8C8C8C;
  font-size: 12px;
  line-height: normal;
  margin: 16px 0;
  opacity: 0.3;
}
.timeline-event-opposite{
  bottom: 50%;
}
.timeline-event-content{
  top: 50%;
}
.timeline-event.active .timeline-event-opposite,
.timeline-event.active .timeline-event-content{
  opacity: 1;
}
.timeline-event-separator{
  margin: 26px 0;
  background: #dcdcdc;
  height: 3px;
  position: relative;
}
.timeline-event-separator:before{
  content: '';
  width: calc(100% - 8px);
  height: 100%;
  background: #dcdcdc;
  top: 0;
  right: 100%;
  position: absolute;
  z-index: 0;
}
.timeline-event.active .timeline-event-separator{
  background: #8C8C8C;
}
.timeline-event:first-child .timeline-event-separator:before,
.timeline-event.active .timeline-event-separator:before{
  display: none;
}
.timeline-event-marker{
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: #b8ebe5;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.timeline-event.active .timeline-event-marker{
  background: #14BEAA;
}
.timeline-event:last-child .timeline-event-marker{
  background: #fed0c3;
}
.timeline-event:last-child.active .timeline-event-marker{
  background: #FC6337;
}
@media(max-width: 767px){
  .timeline{
    margin-left: 53px;
    margin-right: 53px;
    padding-bottom: 24px;
    margin-bottom: 12px;
  }
  .timeline-event{
    width: 122px;
    flex: 0 0 auto;
  }
  .timeline-event-content{
    font-size: 14px;
  }
}
</style>
